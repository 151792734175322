const tableData = [
    {
      PTID: 'CMR-EIDPOC-0001',
      region: 'Littoral',
      location: 'Douala',
      enrollmentStatus: 2018,
      contactPerson: '675636363',
      telephone: '675636363',
      email: 'joel@admin.com',
      facilityEmail:'joe@admin.com',
      typeOfFacility:'Confessional',
      },
    {
      PTID: 'CMR-EIDPOC-0002',
      region: 'Centre',
      location: 'Bafia',
      enrollmentStatus: 2018,
      contactPerson: '675636363',
      telephone: '675636363',
      email: 'joel@admin.com',
      facilityEmail:'joe@admin.com',
      typeOfFacility:'Confessional',
    },
    {
      PTID: 'CMR-EIDPOC-0002',
      region: 'Centre',
      location: 'Bafia',
      enrollmentStatus: 2018,
      contactPerson: '675636363',
      telephone: '675636363',
      email: 'joel@admin.com',
      facilityEmail:'joe@admin.com',
      typeOfFacility:'Confessional',
    },
];

export { tableData };
