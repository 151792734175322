<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">All Sites</h4>
                            </div>
                            <div class="col-md-10 text-right">
                                <b-button variant="success">
                                    <i class="ri-file-excel-2-line align-middle mr-2"></i> Export
                                </b-button>
                                <b-modal
                                        id="modal-delete"
                                        centered
                                        title="Confirm Delete"
                                        title-class="font-18"
                                        ref="my-modal"
                                        hide-footer

                                >
                                    <center>  <span  >Are you sure you want to Delete?</span></center>
                                    <div class="text-center">
                                        <button
                                                class="btn btn-danger w-md waves-effect waves-light mt-4"
                                                @click="hideModal"
                                        >No</button>
                                        <button
                                                class="btn btn-primary w-md waves-effect waves-light mt-4 mx-3"
                                                type="submit"
                                                @click="del(selectedID),hideModal()"
                                        >Yes</button>
                                    </div>


                                </b-modal>
                            </div>

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="siteData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"

                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(actions)="{item }">
                                    <b-button  variant="info" @click="view(item)" >
                                        <i class="ri-eye-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" @click="edit(item)" variant="success" >
                                        <i class="ri-edit-fill align-middle "></i>
                                    </b-button>
                                    <b-button variant="danger" v-b-modal.modal-delete @click="choosenID(item)">
                                        <i class="ri-delete-bin-2-fill align-middle "></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </Layout>

</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";

    import { tableData } from "./dataEID";
    import { axios_get,axios_delete} from "../../../helpers/helper";

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "View Sites",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader },
        data() {
            return {

                tableData: tableData,
                siteData:null,
                user:null,
                title: "View all Sites",
                items: [
                    {
                        text: "Sites",
                        href: "/"
                    },
                    {
                        text: "View sites",
                        active: true
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "id",
                sortDesc: false,
                fields: [
                    { key: "ptid", sortable: true },
                    { key: "site_name", sortable: true },
                    { key: "email", sortable: true },
                    { key: "region", sortable: true },
                    { key: "location", sortable: true },
                    //{ key: "enrollment_status", sortable: true },
                    { key: "type_facility", sortable: false },
                    { key: "telephone", sortable: false },
                    { key: "site_category", sortable: true },
                    { key: "actions", sortable: false },
                ],
            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.siteData.length;
            }
        },
        mounted() {
            this.totalRows = this.items.length;
            this.user=JSON.parse(localStorage.getItem('user'))
            axios_get("/sites",{},(d)=>{
                    console.log(d)
                    this.siteData=d.filter(e=>{return e.region.country_id == this.user.country_id})
                    this.siteData=this.siteData.map(e=>{e.region = e.region.name; return e})
                    console.log(this.siteData)
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );
        },
        methods: {
            // editItem(item) { console.log(item); },
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            hideModal() {
                this.$refs['my-modal'].hide()
            },
            view(e){
                console.log(e)
                if(e.site_category === 'VL'){
                    window.location.href = "/site/vl/"+e.id
                }else{
                    window.location.href = "/site/eid/"+e.id
                }
                
            },
            edit(e){
                console.log(e)
                if(e.site_category === 'VL'){
                    window.location.href = "/site/vl/edit-site/"+e.id
                }else{
                    window.location.href = "/site/eid/edit-site/"+e.id
                }
                
            },
            choosenID(e){
                console.log(e)
                this.selectedID=e.id
                console.log(this.selectedID)
                return this.selectedID

            },
            del(e){
                const  index = this.siteData.indexOf(e)
                console.log(e)
                this.site_id=e
                console.log(this.site_id)
                axios_delete("/sites/"+this.site_id,{
                    },
                    (d)=>{
                        console.log(d)
                        this.siteData.splice(index,1)
                        window.makeToast('Success','Site Deleted Successfully','success')
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                    }
                );


            },
        }
    };
</script>

<style scoped>
    .actionBut{
        margin-left: 10px;
        margin-right: 10px;
    }

</style>
